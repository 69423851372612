<template>
  <div class="maxHeight">
    <div class="header flex">
      <div class="title flex">
        <img src="../../assets/img/ex_a.png" alt="" width="28">
        <h2>笼舍管理</h2>
      </div>
    </div>
    <div class="contentBody">
      <div class="cuddlesList flex">
        <div class="list flex" @click="toDetail(item.isUse,item.id)" v-for="item in cuddlesList"
             :key="item.id" :class="{'unUse':item.isUse==0}">
          <div class="frameNo flex commonBg" v-if="item.isUse==1"></div>
          <div class="freeBg commonBg" v-else></div>
          <h4>{{ item.number }}</h4>
          <div class="headUser" v-if="item.isUse==1">
            <span class="username">{{ item.dirNickname }}</span>
            <img src="../../assets/img/isClose.png" alt="" width="16" v-if="item.isClose==1">
          </div>
          <div class="free" v-else>空闲</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "cageVis",
  data() {
    return {
      cuddlesList: [],
    }
  },
  computed: {
    ...mapState({
      user: state => state.account.user
    })
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$get('/cage/visitor').then((res) => {
        if (res.status == 200) {
          let arr = []
          for (let key in res.data) {
            res.data[key].forEach(item => {
              arr.push(item)
            })
          }
          this.cuddlesList = arr
        }
      }).catch(err => {
        console.log(err)
      })
    },
    toDetail(isUse, id) {
      if (isUse == 1) {
        this.$router.push({name: 'cuddlesDetail', query: {id: id}})
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/exper/cuddles";
</style>
